"use client" // Error components must be Client Components

import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Theme,
  useTheme,
} from "@chakra-ui/react"
import { useEffect } from "react"

const errorBoundaryMessage =
  "The PowerUp Project Planner uses values contained in your browser's local storage that can experience issues with syncing as we push new updates. We appreciate your patience."

const resetButtonMessage =
  "Click the button below to clear your browser's cache and start fresh."

export default function Error({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to Sentry, our error reporting service
    console.error(`BoundaryError: ${error}`)
  }, [error])

  const theme = useTheme() as Theme

  return (
    <Box
      width="100vw"
      height="100vh"
      backgroundColor={theme.colors.black["500"]}
      display={"flex"}
      justifyContent="center"
      alignItems="center"
    >
      <Card maxWidth="1000px">
        <CardHeader>
          <Heading>Let&#39;s try that again</Heading>
        </CardHeader>
        <CardBody>
          <Text fontSize={{ base: "4vw", sm: "xl" }}>
            {errorBoundaryMessage}
          </Text>
          <Text fontSize={{ base: "4vw", sm: "xl" }} mt={"1rem"}>
            {resetButtonMessage}
          </Text>
        </CardBody>
        <CardFooter>
          <Button
            size={"lg"}
            width={"100%"}
            mx={"1rem"}
            backgroundColor={theme.colors.black["500"]}
            color={theme.colors.white["500"]}
            _hover={{ bg: theme.colors.gray["800"] }}
            onClick={
              // Attempt to recover by trying to clear localStorage and then re-render the segment
              () => {
                localStorage.removeItem("home-info")
                localStorage.removeItem("appliance-info")
                // TODO we may want to be more specific about what we clear and use removeItem for the specific keys: "home-info", "appliance-info", "feature-flags"
                reset()
              }
            }
          >
            Reset application
          </Button>
        </CardFooter>
      </Card>
    </Box>
  )
}
